import { Controller } from "stimulus"
import AxiosRequest from "../utils/axios-request"
import SecureLS from 'secure-ls'

export default class extends Controller
  otherPayment: ->
    dev = @data.get('dev')
    if JSON.parse dev
      router_api = 'http://dev.allkryon.com:3006'
    else
      router_api = 'https://api.kryon.app'

    idshopping_cart = @data.get('cart')
    uid = @data.get('uid')
    jwt = @data.get('jwt')

    $.get '/payment_methods', (res) =>
      option_payment = res.payment_methods.map((e) => "<option value='#{e.idpayment_method}'>#{e.name}</option>")
      Swal.fire({
        confirmButtonText: 'Continuar'
        cancelButtonText: 'Cancelar'
        html:
          "
          <h3> Selecciona el metodo de pago </h3>
          <select name='payment_method' class='swal2-input uk-input' id='payment_method'><option value=''>Selecciona una opción</option>#{option_payment}</select>"
        preConfirm: ->
          payment_method = document.getElementById('payment_method').value
          if payment_method == ''
            Swal.showValidationMessage('Selecciona una opción.')
        showCloseButton: true,
        showCancelButton: true,
      }).then (data) ->
        if data.value
          payment_method_id = document.getElementById('payment_method').value
          authenticity_token = $('[name="csrf-token"]')[0].content

          final_data = {
            idshopping_cart,
            payment_method_id,
            authenticity_token
          }

          request = $.ajax(
            {
              url: "#{router_api}/api/v1/transactional/#{uid}/counter_deliveries",
              data: JSON.stringify(final_data),
              method: 'post',
              headers: { 'Authorization': "Bearer #{jwt}" }
              processData: false
              contentType: "application/json"
            }
          )
          request.done (data) ->
            # Borramos el promotional code cuando t.o.d.o termine y carrito
            ls = new SecureLS()
            ls.remove('cart')
            ls.remove('discount')
            Swal.fire({
              icon: 'success',
              text: data.message
              timer: 8000
              timerProgressBar: true
            }).then (val) ->
              window.location.replace("/customers/account/#{uid}")
          request.fail (data) ->
            Swal.fire({
              icon: 'error',
              title: 'hubo un error al solicitar pagar contra entrega, intentelo de nuevo más tarde.'
              timer: 8000
              timerProgressBar: true
            })
