import { Controller } from "stimulus"
import AxiosRequest from "../utils/axios-request"
import UiKit from 'uikit'

export default class extends Controller
  deletefavourite: ->
    modal = @element.parentNode.parentNode.parentNode
    iditem = @data.get('iditem')
    customer_id = @data.get('idcustomer')
    Swal.fire(
      title: '¿Estás seguro?'
      text: ''
      icon: 'warning'
      showCancelButton: true
      confirmButtonColor: '#30bbe5'
      cancelButtonColor: '#d33'
      cancelButtonText: 'Cancelar'
      confirmButtonText: 'Sí, quitar favorito').then (result) =>
        if result.value
          AxiosRequest("/customers/favourites/remove", {item_id: iditem, customer_id: customer_id}, 'post').then ({data}) =>
            Swal.fire({
              title: data.message
              icon: 'success'
              toast: true
              position: 'top-end'
              timer: 2000
            }).then =>
              if modal
                UiKit.modal(modal).hide();
                document.getElementById('delete-favorite-' + modal.id).classList.replace('uk-visible', 'uk-hidden')
                document.getElementById('add-favorite-' + modal.id).classList.replace('uk-hidden', 'uk-visible')
                document.getElementById(modal.id + '-favourite').classList.add('uk-invisible')
        else if result.dismiss == Swal.DismissReason.cancel
          Swal.fire({
            title: 'Cancelado'
            icon: 'error'
            toast: true
            position: 'top-end'
            timer: 2000
          }).then ->
            console.log 'Cancelado'
        return
