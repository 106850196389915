import {Controller} from "stimulus"
import AxiosRequest from "../utils/axios-request"

export default class extends Controller
  @targets: ['form']

  cartLocker: ->
    @cart = @data.get 'scid'
    @uid = @data.get 'uid'
    @env = @.data.get 'env'
    @jwt = @.data.get 'jwt'

    if JSON.parse @env
      router_api = 'http://dev.allkryon.com:3006'
    else
      router_api = 'https://api.kryon.app'
    authenticity_token = $('[name="csrf-token"]')[0].content
    final_data = {
      authenticity_token
    }
    request = $.ajax(
      {
        url: "#{router_api}/api/v1/customers/#{@uid}/shopping_carts/#{@cart}/lock",
        data: JSON.stringify(final_data),
        method: 'post',
        headers: { 'Authorization': "Bearer #{@jwt}" }
        processData: false
        contentType: "application/json"
      }
    )

    request.done (data) =>
      @formTarget.submit()
    request.fail (data) ->
      Swal.fire({
        icon: 'error'
        title: 'Error'
        text: data.responseJSON.message
        showConfirmButton: false
        timer: 3000
      }).then -> location.reload()
